import React from "react";
import PropTypes from "prop-types";

const CustomButton = ({ title, type, varient, onClick, className }) => {
  let classes;
  switch (varient) {
    case "success":
      classes = "bg-blue-light focus:ring-blue-300";
      break;
    case "error":
      classes = "bg-red-600 focus:ring-red-500";
      break;
    case "disabled":
      classes = "bg-grey-600 focus:ring-grey-100";
      break;

    default:
      classes = "bg-red-600 focus:ring-red-500";
      break;
  }
  return (
    <button
      type={type}
      className={`text-white w-full ${classes} focus:ring-2 focus:outline-none  font-medium rounded text-sm px-7 py-2 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${className}`}
      onClick={type === "button" && varient !== "disabled" ? onClick : () => {}}
    >
      {title}
    </button>
  );
};

CustomButton.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  varient: PropTypes.oneOf(["success", "error"]),
};

CustomButton.defaultProps = {
  title: "Button",
  type: "button",
  varient: "success",
};

export default CustomButton;
