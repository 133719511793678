import React from "react";
import AntdDrawer from "antd/lib/drawer";
import CustomButton from "components/Atoms/CustomButton";

const Drawer = ({
  isOpen,
  setDrawer,
  children,
  title,
  subTitle,
  buttonTitle,
  buttonTitleHandler,
}) => {
  return (
    <>
      <AntdDrawer
        title={
          <div>
            <div className="float-left">
              <div> {title} </div>
              <div className="text-sm font-normal"> {subTitle} </div>
            </div>
            {buttonTitle != null && (
              <div className="float-right">
                <CustomButton title={buttonTitle} onClick={buttonTitleHandler}>
                  Button
                </CustomButton>
              </div>
            )}
          </div>
        }
        width={"60%"}
        onClose={() => setDrawer(false)}
        open={isOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={<></>}
      >
        {children}
      </AntdDrawer>
    </>
  );
};

export default Drawer;
