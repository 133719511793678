/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
// import Breadcrumb from "antd/lib/breadcrumb";
import Popover from "antd/lib/popover";
import { menuItems, sidebarLinks } from "utils/constants";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IoLogOut, IoEllipsisVerticalOutline } from "react-icons/io5";
import { useAuth } from "context/auth";
// import talkerIcon from "images/icons/talker.png";
import WorkspaceSwitch from "components/Molecules/WorkspaceSwitch";
import useSpace from "hooks/useSpace";
import { getDefaultWorkspace } from "utils/helper";
import api from "utils/api";
import CustomButton from "components/Atoms/CustomButton";
import useSubscription from "hooks/useSubscription";
import { Button, Typography } from 'antd';
import isEqual from "react-fast-compare";


const { Text } = Typography;

const { Header, Content, Sider } = Layout;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [list, setList] = useState([]);
  const [activeKey, setKey] = useState('2');
  const navigate = useNavigate();
  const { logout, data: appData, setData } = useAuth();
  const { data: subscription } = useSubscription();
  const { data: spaces } = useSpace();

  const location = useLocation();
  const getUser = async () => {
    try {
      const user = await api.get("auth/me");
      setData({ user });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/dashboard/blogs':
        setKey('3')
        break;
      case '/dashboard/settings':
        setKey('5')
          break;
      default:
        break;
    }
  }, [location])


  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (spaces) {
      setData({ workspace: getDefaultWorkspace(spaces) });
    }
  }, [isEqual(spaces)]);

  useEffect(() => {
    if (appData.workspace) {
      setList(spaces?.filter((x) => x.id !== appData.workspace.id));
    }
  }, [appData?.workspace]);

  useEffect(() => {
    if (subscription) {
      setData({ subscription: subscription });
    }
  }, [subscription]);

  const goToSettings = () => {
    navigate("settings?step=6");
  }

  const remainingBlogs = (sub) => {
    return sub?.active ? (sub?.monthlyBlogs + sub?.addonBlogs - sub?.usedBlogs)
      : (sub?.monthlyBlogs);
  }


  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        collapsible
        theme="light"
        collapsed={collapsed}
        onCollapse={(value) => {
          if (value) {
            document
              .querySelectorAll("#sidebar-icon-aichat")
              ?.forEach((icon) => {
                icon.classList.add("sidebar-icon-style");
              });
          } else {
            document
              .querySelectorAll("#sidebar-icon-aichat")
              ?.forEach((icon) => {
                icon.classList.remove("sidebar-icon-style");
              });
          }
          setCollapsed(value);
        }}
        className="font-poppin"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col">
            <Popover
              content={<WorkspaceSwitch spaces={spaces} list={list} />}
              placement="rightTop"
              trigger="click"
            >
              {collapsed ? (
                <div className="mt-4 cursor-pointer">
                  <img
                    src={
                      "https://ui-avatars.com/api/?name=" +
                      appData?.workspace?.name
                    }
                    alt=""
                    height={30}
                    width={30}
                    className="m-auto"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-around mt-4 cursor-pointer">
                  <div className="flex items-center">
                    <img
                      src={
                        "https://ui-avatars.com/api/?name=" +
                        appData?.workspace?.name
                      }
                      alt=""
                      height={30}
                      width={30}
                    />
                    <div className="font-poppin text-grey-600 text-lg font-semibold ml-2">
                      {appData?.workspace?.name}
                    </div>
                  </div>
                  <div>
                    <IoEllipsisVerticalOutline />
                  </div>
                </div>
              )}
            </Popover>
            <Menu
              theme="light"
              selectedKeys={[activeKey]}
              mode="inline"
              onSelect={(e) => {
                navigate(sidebarLinks[e.key]);
              }}
              items={menuItems(activeKey)}
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            />
          </div>
          <div>
          {collapsed ? (
              <div className="flex bg-[#F2FAFF] m-2 rounded-md">
                <div className="mt-4 cursor-pointer">
                  <div className="flex flex-col justify-center">
                    <Text mark>{remainingBlogs(subscription)} Blogs Left</Text>
                  </div>
                  {subscription?.active ? (
                  <div className="text-xs">
                    {" "}
                    Eligible for addon blogs{" "}
                  </div>
                  ) : (
                    <div> No Active Plan! </div>
                  )}
                  <div className="w-[30px]">
                    {" "}
                    <Button
                      type="link"
                      title="Buy"
                      onClick={goToSettings}
                      size="small"
                    >Billing</Button>
                  </div>
                </div>
              </div>
              ) : (
            <div className="flex bg-[#F2FAFF] m-2 rounded-md">
              <div className="flex flex-col justify-center p-5 gap-3">
                <div>
                  <div className="text-lg">
                    {remainingBlogs(subscription)} Blogs Remaining
                  </div>
                </div>
                {subscription?.active ? (
                  <div className="text-xs">
                    {" "}
                    Get add on blog packs that can be used during your subscription{" "}
                  </div>
                ) : (
                  <div> No Active Plan! </div>
                )}
                <div className="w-[150px]">
                  {" "}
                  <CustomButton
                    title={subscription?.active ? "Addons" : "Upgrade"}
                    onClick={goToSettings}
                  />{" "}
                </div>
              </div>
            </div>
              )}
            <div className="logout-custom" onClick={() => logout(true)}>
              <Menu
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
                mode="inline"
                className="text-xl"
                items={[
                  {
                    key: 6,
                    icon: (
                      <IoLogOut
                        id="sidebar-icon-aichat"
                        style={{
                          fontSize: "30px",
                          margin: "auto",
                          color: "#FFF",
                        }}
                      />
                    ),
                    label: (
                      <div className="font-poppin text-base text-white">
                        {" "}
                        Logout{" "}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            backgroundColor: "#FFFFFF",
            padding: 0,
            height: 50,
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
          className="flex items-center"
        >
          <div className="flex">
            <img
              src="https://kwegg.com/wp-content/uploads/2021/05/kwegglogodark.svg"
              alt="Kwegg Content Productivity System | Create, Manage and Publish AI Generated Content"
            />
          </div>
          {/* <div className="ml-8">
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                className="cursor-pointer"
                onClick={() => navigate("/dashboard/")}
              >
                Dashboard
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="cursor-pointer"
                onClick={() => navigate("/dashboard/blogs")}
              >
                {" "}
                Blogs{" "}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div> */}
        </Header>
        <Content className="m-8">
          <div>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
