import React, { createContext, useContext } from "react";
import message from "antd/lib/message";

const getConfig = (type, content) => {
  return {
    type,
    content,
    style: {
      position: "absolute",
      top: 5,
      right: 5,
    },
  };
};

function AlertProvider({ children }) {
  const [alert, contextHolder] = message.useMessage();

  const success = (msg) => alert.open(getConfig("success", msg));
  const error = (msg) => alert.open(getConfig("error", msg));

  return (
    <AlertContext.Provider value={{ success, error }}>
      {contextHolder}
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
export const AlertContext = createContext();

export const useAlert = () => {
  return useContext(AlertContext);
};

