import React from "react";
import PropTypes from "prop-types";
import AntdSelect from "antd/lib/select";

const Select = ({ name, options, id, onChange, value, error, touched, mode = '', placeholder, dropdownRender }) => {
  const isError = touched && error;

  const setValue = (selected) => {
    onChange(id,selected)
  }
  return (
    <div className="font-poppin mb-2.5">
      <label
        className="block mb-1 text-sm font-normal text-gray-900 dark:text-gray-300"
        htmlFor={id}
      >
        {name}
      </label>
      <AntdSelect
        mode={mode}
        className="w-full rounded-md border text-grey-600 border-grey-600 focus:ring-blue-500 focus:border-blue-500"
        size="large"
        bordered={false}
        onChange={setValue}
        value={value}
        id={id}
        options={options}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
      />
      {isError && <div className="text-xs text-red-500 mr-1"> {error} </div>}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.string,
  mode: PropTypes.oneOf(['multiple','tags',''])
};

Select.defaultProps = {
  options: []
}

export default Select;