import React from "react";
import PropTypes from "prop-types";

const Input = ({ name, type, id, onChange, value, error, touched, ...rest }) => {
  const isError = touched && error;
  return (
    <div className="font-poppin mb-2.5">
      <label
        className="block mb-1 text-sm font-normal text-gray-900 dark:text-gray-300"
        htmlFor={id}
      >
        {name}
      </label>
      <input
        {...rest}
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        className={`rounded-md border text-grey-600 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5 ${
          isError ? "border-red-500" : "border-grey-600"
        }`}
      />
      {isError && <div className="text-xs text-red-500 mr-1"> {error} </div>}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
};

export const TextArea = ({ name, id, onChange, value, rows, ...rest }) => {
  return (
    <div className="font-poppin mb-2.5">
      <label
        className="block mb-1 text-sm font-normal text-gray-900 dark:text-gray-300"
        htmlFor={id}
      >
        {name}
      </label>
      <textarea
        {...rest}
        id={id}
        rows={rows}
        value={value}
        onChange={onChange}
        className="rounded-md border text-grey-600 border-grey-600 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
      />
    </div>
  );
};

export default Input;
