/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import useMergeState from "hooks/mergeState";
import api from "utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "context/alert";
import { getDefaultWorkspace } from "utils/helper";
export const AuthContext = createContext();


const VerifyUser = () => {
  const showMsg = useContext(AlertContext);
  const { authtoken } = useParams();
  const navigate = useNavigate();
  const [isAuthenticated, setAuth] = useState(false);
  const [token, setToken] = useState(false);
  const [appData, setAppData] = useMergeState({workspace: "", user: null, subscription: null});

  const verifyUser = async () => {
    try {
      const data = await api.get(`auth/verify/${authtoken}`);
      showMsg.success("You are verified successfully");
      if (data?.isVerified) {
        setAuth(true);
        setToken(authtoken);
        setAppData({ user: data })
        const spaces = await api.get("space");
        if (spaces?.length) {
          setAppData({ workspace: getDefaultWorkspace(spaces)})
          navigate('/dashboard/blogs');
        } else {
          navigate('/workspace-create');
        }
      } else {
        showMsg.success(
          "Authentication Failed. Try again."
        );
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
      showMsg.error(error.message);
      navigate('/login');
    }
  };

  useEffect(() => {
    if (authtoken) {
      verifyUser();
    } else {
      navigate('/login');
    }
  }, []);

  return <div> Verifying..</div>;
};

VerifyUser.propTypes = {};

export default VerifyUser;
