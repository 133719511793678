import React from "react";
import Input, { TextArea } from "components/Atoms/Input";
import CustomButton from "components/Atoms/CustomButton";
import { useFormik } from "formik";
import api from "utils/api";
import { useAlert } from "context/alert";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/auth";
import Select from "components/Atoms/Select";
import { Divider } from "antd";
import { fieldHelperTexts } from "utils/constants";
import * as Yup from "yup";

const WorkspaceSchema = Yup.object().shape({
  overview: Yup.string()
    .min(20, "Minimum 20")
    .max(fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"], "Too Long!")
    .required("Required"),
});

const WorkspaceForm = () => {
  const { success, error } = useAlert();
  const { setData } = useAuth();
  const navigate = useNavigate();
  const formik = useFormik({
    validationSchema: WorkspaceSchema,
    initialValues: {
      name: "",
      features: [],
      blogCategories: [],
      overview: "",
    },
    onSubmit: async (values) => {
      try {
        const data = await api.post("space", {
          ...values,
          features: values.features?.join("|"),
          blogCategories: values.blogCategories?.join("|"),
        });
        setData({ workspace: data });
        success("Workspace is created!");
        navigate("/dashboard/blogs");
      } catch (err) {
        console.log(err);
        error(err.message ? err.message[0] : "Error while creating workspace");
      }
    },
  });

  return (
    <div className="px-12 mt-3">
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Input
            name="Name of your workspace"
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder={fieldHelperTexts["WORKSPACE_NAME"]}
          />
        </div>
        <div>
          <TextArea
            name="3-4 lines overview of your workspace"
            rows={4}
            id="overview"
            type="textarea"
            onChange={formik.handleChange}
            value={formik.values.overview}
            placeholder={fieldHelperTexts["WORKSPACE_OVERVIEW"]}
          />
          <p align="right">
            <small>
              <span style={{ color: "red", fontWeight: "bolder" }}>{formik.errors.overview}</span>{" "}
              {formik.values.overview.length}/
              {fieldHelperTexts["SPACE_OVERVIEW_CHARLIMIT"]} characters
            </small>
          </p>
        </div>
        <div>
          <Select
            name="And few features not covered in overview"
            id="features"
            mode="tags"
            options={[]}
            onChange={formik.setFieldValue}
            value={formik.values.features}
            placeholder={fieldHelperTexts["WORKSPACE_FEATURES"]}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div>
                  <b>start typing and select option to add</b>
                </div>
              </div>
            )}
          />
        </div>
        <div>
          <Select
            name="Search Engine keywords you want to generate content for."
            id="blogCategories"
            mode="tags"
            onChange={formik.setFieldValue}
            value={formik.values.blogCategories}
            placeholder={fieldHelperTexts["WORKSPACE_BLOG_CATEGORIES"]}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: "4px 0" }} />
                <div>
                  <b>type and select keywords to add</b>
                </div>
              </div>
            )}
          />
        </div>
        <div className="mb-5 mt-6">
          <CustomButton type="submit" title="Start Generating Content" />
        </div>
        {localStorage.getItem("selectedWorkspace") && (
          <div className="flex text-blue-light">
            <div
              className="w-9 cursor-pointer content-center"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

WorkspaceForm.propTypes = {};

export default WorkspaceForm;
