import { createBrowserRouter } from "react-router-dom";
import Login from "containers/Auth/Login";
import WorkspaceCreate from "containers/Workspace";
import VerifyUser from "containers/Auth/VerifyUser";
import { ProtectedRoute } from "utils/ProtectedRoute";
import Blogs from "containers/Blogs";
import Sidebar from "components/Molecules/Sidebar";
import Dashboard from "containers/Dashboard";
import Settings from "containers/Settings";
import IndexRoute from "containers/Auth/IndexRoute";

const routes = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    index: true,
    element: <IndexRoute />,
  },
  {
    path: "/workspace-create",
    element: <WorkspaceCreate />,
  },
  {
    path: "/workspace/:workspaceId",
    element: <WorkspaceCreate />,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Sidebar />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/dashboard/",
        element: <Dashboard />
      },
      {
        path: "/dashboard/blogs",
        element: <Blogs />
      },
      {
        path: "/dashboard/settings",
        element: <Settings />
      }
    ]
  },
  {
    path: "/verify/:authtoken",
    element: <VerifyUser />,
  },
]);

export default routes;
