import React from "react";
import PropTypes from "prop-types";
import { AiOutlineSound, AiOutlineDrag, AiOutlineDelete } from "react-icons/ai";
import { MdDragIndicator } from "react-icons/md";
import { BiNotepad } from "react-icons/bi";
import { Spin, Typography, Popconfirm } from "antd";
import { fieldLabels } from "utils/constants";

const { Text, Link } = Typography;

const Card = ({ data, openDrawer, deleteArticle }) => {
  const { tone, title, strategy, stages, media, overview } = data;

  const clickToEdit = () => {
    // if(stages === "CREATE_DRAFT")
    openDrawer(data);
  };

  return (
    <div className="p-3 min-h-[117px] flex flex-col justify-between">
      {stages === "CREATE_OUTLINE" && (
        <div className="flex">
          <div className="mr-2">
            <Spin /> <Text code>Generating Outlines</Text>
          </div>
        </div>
      )}
      {stages === "CREATE_DRAFT" && (
        <div className="flex">
          <div className="mr-2">
            <Spin /> <Text code>Generating final draft</Text>
          </div>
        </div>
      )}
      <div
        className={
          stages == "CREATE_OUTLINE" || stages == "CREATE_DRAFT"
            ? "blur-sm"
            : ""
        }
      >
        <div className="flex justify-between items-center">
          {tone ? (
            <div
              className={`flex items-center px-1 py-0.5 rounded-md ${
                tone === "PROFESSIONAL" ? "bg-green-200" : "bg-yellow-200"
              }`}
            >
              <div className="mr-1">
                <AiOutlineSound className="text-white" fontSize={18} />
              </div>
              <div className="uppercase text-white text-sm">{tone}</div>
            </div>
          ) : (
            <div />
          )}
          {stages === "TITLE_CREATED" ? (
            <div className="flex">
              <div
                className="cursor-pointer"
                onClick={() => console.log("first")}
              >
                 <Popconfirm
                    title="Delete the Article"
                    description="Are you sure to delete this article?"
                    onConfirm={() => deleteArticle(data?.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                  <AiOutlineDelete fontSize={19} />
                </Popconfirm>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="flex">
            <div className="mr-2">
              <MdDragIndicator className="text-blue-dark" fontSize={18} />
            </div>
          </div>
        </div>
        {media && (
          <div className="my-2" onClick={clickToEdit}>
            <img src={JSON.parse(media).url} />
          </div>
        )}
        <div className="my-2" onClick={clickToEdit}>
          <div>{title}</div>
          {stages === "TITLE_CREATED" ? (
            overview && <small>{overview}</small>
          ) : (
            <small></small>
          )}
        </div>

        <div className="flex justify-between">
          <div className="flex items-center">
            <div
              className={`rounded-[4px] p-[1px] mr-1 ${
                strategy !== "CONTEXTUALLY_ONCE" ? "bg-teal-500" : "bg-red-600"
              }`}
            >
              <BiNotepad className="text-white" fontSize={16} />
            </div>
            <div className="uppercase text-xs font-semibold">
              {fieldLabels[strategy]}
            </div>
          </div>

          <div> </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  blogtype: PropTypes.string,
  title: PropTypes.string,
  strategy: PropTypes.string,
};

export default Card;
