import React from "react";
import Input from "components/Atoms/Input";
import CustomButton from "components/Atoms/CustomButton";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import api from "utils/api";
import * as Yup from "yup";
import { useAlert } from "context/alert";
import { useState } from "react";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string()
      .email("Invalid email")
      .required("Required"),
  name: Yup.string()
    .required("Name is Required")
});

const RegisterForm = ({ showLogin }) => {
  const { success, error } = useAlert();
  const [processing, setProcessing] = useState(false);

  const formik = useFormik({
    validationSchema: LoginSchema,
    initialValues: {
      email: "",
      password: "",
      name: ""
    },
    onSubmit: async (values) => {
      setProcessing(true);
      try {
        await api.post("auth/register", values);
        success("User is created Please check the email for verification..!");
        showLogin(true);
      } catch (err) {
        console.log(err);
        error(err?.message[0]);
      }
      setProcessing(false);
    },
  });
  return (
    <div className="pl-12 pr-12 md:pr-28">
      <div className="text-4xl font-bold">Join Kwegg</div>
      <div className="font-normal text-sm text-grey-600 pt-3">
        World's #1 Content Productivity System
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="mt-6">
        <div>
            <Input
              name="Name"
              id="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.name}
              touched={formik.touched.name}
            />
          </div>
          <div>
            <Input
              name="Email address"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
          </div>
          <div>
            <Input
              name="Password"
              type="password"
              id="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
        </div>
        <div className="mt-7">
          <CustomButton varient={processing ? "disabled":"success"} title="Create Account" type="submit" />
        </div>
      </form>
      <div className="flex text-xs mt-3">
        <div className="mr-1">Already have an account?</div>
        <div className="text-blue-light cursor-pointer" onClick={() => {showLogin(true)}} >Login here</div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  showLogin: PropTypes.func.isRequired,
};

export default RegisterForm;
