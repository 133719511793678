import React from "react";
import desktopImg from "images/desktop.png";
import WorkspaceForm from "components/Molecules/Forms/Workspace";
import { useParams } from "react-router-dom";
import WorkspaceFormEdit from "components/Molecules/Forms/EditWorkspace";

const WorkspaceCreate = (props) => {
  const { workspaceId } = useParams();

  return (
    <div className="login-background w-full h-screen font-poppin">
      <div className="max-w-5xl mx-auto px-8">
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white w-full md:w-[650px] py-16 relative bg-gray-100 rounded">
            <div className="px-16 text-center">
              <div className="text-3xl mt-2"> {workspaceId ? "Edit Your Workspace" : "Let’s Train our AI now" }</div>
              <div className="text-sm mt-2">
                <p>You can create any number of workspaces over Kwegg. </p>
                Each workspace represents your content website for your product or service organisation. Information provided by you here will be used by Kwegg AI while generating blogs. 
              </div>
            </div>
            {workspaceId ? (
              <WorkspaceFormEdit id={workspaceId} />
            ) : (
              <WorkspaceForm />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

WorkspaceCreate.propTypes = {};

export default WorkspaceCreate;
