import useMergeState from "hooks/mergeState";
import React, { createContext, useContext, useEffect, useState } from "react";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuth] = useState(false);
  const [token, setToken] = useState(false);
  const [appData, setAppData] = useMergeState({workspace: "", user: null, subscription: null});

  const setLogin = (token) => {
    setAuth(true);
    if (token) {
      setToken(token);
    }
  }

  const setLogout = (redirect) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setAuth(false)
    if (redirect) {
      window.location.href = window.location.origin + '/login'
    }
  }

  // later add the api call to check whether the token is valid if it is a
  // success then continue fetching the login state from localhost token or
  // else logout
  useEffect(() => {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      setLogin(localToken);
    } else {
      setLogout();
    }
  }, [])

  return (
    <AuthContext.Provider value={{ isAuthenticated, setLogin, logout: setLogout, token, data: appData, setData: setAppData }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

