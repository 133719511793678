import React from "react";
import ReactDOM from "react-dom/client";
// tailwind classes
import "./index.css";
// created by dev custom
import "./custom.css";
import reportWebVitals from "./reportWebVitals";
import { SWRConfig } from "swr";
import App from "App";
import { fetcher, globleErrorHandler } from 'utils/helper'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <SWRConfig value={{ fetcher, errorRetryCount: 2, onError: globleErrorHandler }}  >
      <App />
    </SWRConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
