import React, { useEffect, useState } from "react";
import Tabs from "antd/lib/tabs";
import {
  TbUserCircle,
  TbLayoutDashboard,
  TbWorld,
  TbBell,
  TbBrandCodesandbox,
  TbCurrencyDollar,
} from "react-icons/tb";
import Integrations from "components/Molecules/Integrations";
import Profile from "components/Molecules/Profile";
import Billings from "components/Molecules/Billing";
import { useSearchParams } from "react-router-dom";
import WorkspaceTab from "components/Molecules/Forms/WorkspaceTab";

const items = [
  {
    key: "1",
    label: (
      <div className="flex items-center">
        <div className="mr-2">
          <TbUserCircle className="text-lg text-black-200" />
        </div>
        <div className="font-poppin text-black-200">Profile</div>
      </div>
    ),
    children: <Profile />,
    title: "Profile Details",
    subTitle: "Manage Your Profile Settings Here ",
  },
  {
    key: "2",
    label: (
      <div className="flex items-center">
        <div className="mr-2">
          <TbLayoutDashboard className="text-lg text-black-200" />
        </div>
        <div className="font-poppin text-black-200">Workspace</div>
      </div>
    ),
    children: <WorkspaceTab />,
    title: "Workspace settings",
    subTitle: "Manage your team settings here ",
  },
  // {
  //   key: "3",
  //   label: (
  //     <div className="flex items-center">
  //       <div className="mr-2">
  //         <TbWorld className="text-lg text-black-200" />
  //       </div>
  //       <div className="font-poppin text-black-200">Domain</div>
  //     </div>
  //   ),
  //   children: `Content of Tab Pane 3`,
  //   title: "Domain Settings",
  //   subTitle: "Manage Your Domain Settings Here ",
  // },
  // {
  //   key: "4",
  //   label: (
  //     <div className="flex items-center">
  //       <div className="mr-2">
  //         <TbBell className="text-lg text-black-200" />
  //       </div>
  //       <div className="font-poppin text-black-200">Notifications</div>
  //     </div>
  //   ),
  //   children: `Content of Tab Pane 3`,
  //   title: "Notification Settings",
  //   subTitle: "Manage Your Notification Settings Here ",
  // },
  {
    key: "5",
    label: (
      <div className="flex items-center">
        <div className="mr-2">
          <TbBrandCodesandbox className="text-lg text-black-200" />
        </div>
        <div className="font-poppin text-black-200">Integrations</div>
      </div>
    ),
    children: <Integrations />,
    title: "Integration Settings",
    subTitle: "Manage Your Integrations Here ",
  },
  {
    key: "6",
    label: (
      <div className="flex items-center">
        <div className="mr-2">
          <TbCurrencyDollar className="text-lg text-black-200" />
        </div>
        <div className="font-poppin text-black-200">Billings</div>
      </div>
    ),
    children: <Billings />,
    title: "Billings Settings",
    subTitle: "Manage Your Plans and billls Here",
  },
];

const Settings = () => {
  const [active, setActive] = useState("1");

  const [steps] = useSearchParams();

  const step = steps.get('step')

  useEffect(() => {
    if (step) {
      setActive(step)
    }
  }, [step])


  const activeTab = items.find((item) => item.key === active);
  return (
    <div>
      <div className="font-poppin mb-3">
        <div className="text-2xl"> {activeTab.title} </div>
        <div className="text-lg text-grey-600"> {activeTab.subTitle} </div>
      </div>
      <div>
        <Tabs activeKey={active} items={items} onChange={setActive} />
      </div>
    </div>
  );
};

export default Settings;
