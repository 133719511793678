import Input, { TextArea } from "components/Atoms/Input";
import React, { useState } from "react";
import { useFormik } from "formik";
import useStrategy from "hooks/useStrategy";
import useTone from "hooks/useTone";
import Select from "components/Atoms/Select";
import Divider from "antd/lib/divider";
import api from "utils/api";
import CustomButton from "components/Atoms/CustomButton";
import Editor from "components/Molecules/Editor";
import Outline from "../Outline";
import { copyTextToClipboard } from "utils/helper";
import { useAlert } from "context/alert";
import { stages } from "utils/constants";
import { fieldHelperTexts, fieldLabels } from "utils/constants";

const BlogUpdate = ({ data, setDrawer, refetch }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isSocialCopied, setIsSocialCopied] = useState(false);
  const { data: strategies } = useStrategy();
  const { data: tones } = useTone();
  const { success, error } = useAlert();

  const formik = useFormik({
    initialValues: {
      title: data?.title,
      overview: data?.overview,
      introduction: data?.introduction,
      tone: data?.tone?.split("|"),
      strategy: data?.strategy,
      body: data?.body || "",
      socialPost: data?.socialPost || "",
      outline: data?.outline,
    },
    onSubmit: async (values) => {
      try {
        await api.put(`article/${data.id}`, {
          ...values,
          tone: values?.tone?.join("|"),
          strategy: values?.strategy,
        });
        success("Article updating successfully");
        setDrawer(false);
        refetch();
      } catch (err) {
        error("Error while updating article");
      }
    },
  });

  const deleteArticle = async () => {
    try {
      await api.put(`article/${data.id}`, { stages: stages.ARCHIVED });
      success("Article deleted successfully");
      setDrawer(false);
      refetch();
    } catch (err) {
      error("Error while deleting article");
    }
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const copy = copyTextToClipboard(formik.values.body);
    try{
      // If successful, update the isCopied state value
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch(err) {
        error("Error" + err);
        console.log(err);
      };
  };

  const handleSocialCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const copy = copyTextToClipboard(formik.values.socialPost);
    try{
      // If successful, update the isCopied state value
      setIsSocialCopied(true);
      setTimeout(() => {
        setIsSocialCopied(false);
      }, 1500);
    } catch(err) {
        error("Error" + err);
        console.log(err);
      };
  };

  return (
    <div className="font-poppin">
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Input
            name="Title"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            // error={formik.errors.title}
            // touched={formik.touched.title}
          />
        </div>
        <Divider style={{ margin: "10px 0" }} />
        {formik.values.body && (
          <>
            {" "}
            <div className="mt-4">
              <div>
                <div className="text-xl">Blog Generated</div>
                <div className="text-sm text-grey-600">
                  You can edit or modify your blog here.
                </div>
              </div>
              <div className="min-h-[300px] w-100 root-medium-editor-div" id="scroll-container">
                <Editor
                  id="body"
                  data={data}
                  value={formik.values.body}
                  onChange={formik.setFieldValue}
                />
                <p align="right"><small>{formik.values.body.split(" ").length} words/{formik.values.body.length} characters</small></p>
                <p align="right">
                  <a href='#' onClick={handleCopyClick} className="text-sm">
                    <span className="text-blue">{isCopied ? 'Copied!' : 'Copy'}</span>
                  </a>
                </p>
              </div>
            </div>
            <Divider style={{ margin: "10px 0" }} />
          </>
        )}
        {formik.values.socialPost && (
            <div>
              <div>
                <div className="text-xl">Social Media Post</div>
                <div className="text-sm text-grey-600">
                </div>
              </div>
              <TextArea
                name="Use this over social media when sharing published article"
                id="socialPost"
                type="textarea"
                rows={3}
                onChange={formik.handleChange}
                value={formik.values.socialPost}
              />
              <p align="right">
                  <a href='#' onClick={handleSocialCopyClick} className="text-sm">
                    <span className="text-blue">{isSocialCopied ? 'Copied!' : 'Copy'}</span>
                  </a>
                </p>
            </div>
          )}
        <Divider>Training Inputs</Divider>
        <div className="mt-4">
          <div>
            <TextArea
              name="Provided Overview"
              id="overview"
              type="textarea"
              rows={4}
              onChange={formik.handleChange}
              value={formik.values.overview}
              placeholder={fieldHelperTexts["OVERVIEW_HELPER"]}
            />
            {formik.values.overview && (
              <p align="right"><small>{formik.values.overview.length} characters</small></p>
            )}
          </div>
          {formik.values.introduction && (
            <div>
              <TextArea
                name="Introduction"
                id="introduction"
                type="textarea"
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.introduction}
              />
              <p align="right"><small>{formik.values.introduction.length} characters</small></p>
            </div>
          )}
          <div>
            <Select
              name="Tone"
              mode="tags"
              id="tone"
              options={tones}
              onChange={formik.setFieldValue}
              value={formik.values.tone}
              error={formik.errors.tone}
              touched={formik.touched.tone}
              placeholder={fieldHelperTexts["TONE_HELPER"]}
            />
          </div>
          <div>
            <Select
              name="Outlines Expansion Strategy"
              id="strategy"
              options={strategies}
              onChange={formik.setFieldValue}
              value={formik.values.strategy}
              error={formik.errors.strategy}
              touched={formik.touched.strategy}
              placeholder={fieldHelperTexts["STRATEGY_HELPER"]}
            />
            <small>{(formik.values.strategy=='') ? "Select a strategy to learn more.":fieldHelperTexts[formik.values.strategy]}</small>
          </div>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        {data?.outline && (
          <div className="mt-4">
            <div className="mb-3">
              <div className="text-xl">Blog Outline</div>
              <div className="text-sm text-grey-600">
                Drag or delete the subtitles as your preference
              </div>
            </div>
            <Outline data={data?.outline} setData={formik.setFieldValue} />
          </div>
        )}
        <div>
          <div className="mt-7 flex">
            <CustomButton
              title="Delete"
              type="button"
              varient="error"
              onClick={deleteArticle}
            />
            <CustomButton title="Update" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BlogUpdate;
