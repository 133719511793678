import React from "react";
import { useFormik } from "formik";
import Input, { TextArea } from "components/Atoms/Input";
import CustomButton from "components/Atoms/CustomButton";
import Space from "antd/lib/space";
import { fieldHelperTexts } from "utils/constants";

import api from "utils/api";
import Select from "components/Atoms/Select";
import useStrategy from "hooks/useStrategy";
import useTone from "hooks/useTone";
import { useAlert } from "context/alert";

const BlogForm = ({ workspace, setDrawer, refetch }) => {
  const { data: strategies } = useStrategy();
  const { data: tones } = useTone();
  const { success, error } = useAlert();

  const formik = useFormik({
    initialValues: {
      title: "",
      overview: "",
      tone: [],
      strategy: [],
    },

    onSubmit: async (values, actions) => {
      try {
        await api.post(`article/${workspace}`, {
          ...values,
          tone: values.tone.join("|"),
          strategy: values.strategy,
        });
        refetch();
        success("Article created!");
        actions.resetForm();
        setDrawer(false);
      } catch (err) {
        console.log(err);
        error((err.message) ? err.message[0] : "Error while creating article");
      }
    },
  });

  const generateTitle = async (e) => {
    e.preventDefault();
    formik.setFieldValue('title', 'Generating..');
    try {
      var resp = await api.post(`article/${workspace}/generate-title`, {
        idea: formik.values.overview,
      });
      formik.setFieldValue('title', resp);
      success("Title generated");
    } catch (err) {
      formik.setFieldValue('title', formik.values.title);
      error("Error while generating title");
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <div>
          <TextArea
            name="Brief Overview"
            type="overview"
            id="overview"
            rows={3}
            onChange={formik.handleChange}
            value={formik.values.overview}
            error={formik.errors.overview}
            touched={formik.touched.overview}
            placeholder={fieldHelperTexts["OVERVIEW_HELPER"]}
          />
          <p align="right"><small>Kwegg AI gives a lot of emphasis on information you provide here. Provide crisp points, dont worry much about sentence structuring or grammar.</small></p>
          <p align="right"><small>{formik.values.overview.length} characters</small></p>
          <p align="right" style={{color: "green"}}><a href="#" onClick={generateTitle}><b>Generate Title from overview</b></a></p>
        </div>
        <div>
          <Input
            name="Title"
            id="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
            touched={formik.touched.title}
            placeholder={fieldHelperTexts["TITLE_HELPER"]}
          />
        </div>
        <div>
          <Select
            name="Tone"
            mode="tags"
            id="tone"
            options={tones}
            onChange={formik.setFieldValue}
            value={formik.values.tone}
            error={formik.errors.tone}
            touched={formik.touched.tone}
            placeholder={fieldHelperTexts["TONE_HELPER"]}
          />
        </div>
        <div>
          <Select
            name="Outlines Expansion Strategy"
            id="strategy"
            options={strategies}
            onChange={formik.setFieldValue}
            value={formik.values.strategy}
            error={formik.errors.strategy}
            touched={formik.touched.strategy}
            placeholder={fieldHelperTexts["STRATEGY_HELPER"]}
          />
          <small>{(formik.values.strategy=='') ? "Select a strategy to learn more.":fieldHelperTexts[formik.values.strategy]}</small>
        </div>
      </Space>
      <div className="mt-7 flex">
        <CustomButton title="Cancel" size={"small"} type="link" varient="error" onClick={() => setDrawer(false)}  />
        <CustomButton title="Create" type="submit" />
      </div>
    </form>
  );
};

BlogForm.propTypes = {};

export default BlogForm;
